import React, { Component } from "react";
import { Header, Form, Divider, Grid, Button, Loader, Select, Icon, Checkbox } from "semantic-ui-react";
import { configsAPI, pipelinesAPI } from "../SDK/client"
import DeleteModal from "../Components/Modals/deleteModal"
import ErrorModal from "../Components/Modals/errorModal"
import StatusBar from "../Components/updateStatusBar"
import SchemaDisplay from "../Components/configSchemaCreator"

class ConfigEditPage extends Component {
  state = {
    pipelineTypes: [],
    inspectError: false, 
    showUpdateStatus: false, 
    responseOK: "", 
    responseError: "",
    errorInfo: "",
    waitingResponse: false,
    config: { 
      configJson: true,
      source: "",
      entity: ""
    },
    configEdits: { 
      configJson: false
    }
  };

  /* Make initial API calls to load config and pipeline dropdown options */
  componentDidMount = () => {
    pipelinesAPI.LOAD_PIPELINE_TYPES(pipelineTypes => {
      pipelineTypes.map(pipelineType => {
        return pipelinesAPI.GET_SCHEMA(pipelineType.id, schema => {
          this.setState((current) => {
            return {
              ...current,
              pipelineTypes: current.pipelineTypes.concat([{ type: pipelineType.type, schema }])
            }
          })
        })
      })
    })
    configsAPI.GET_CONFIG(config => 
      this.setState({ 
        config, 
        configEdits: {configJson: config.configJson} }), 
        this.props.source, this.props.entity)
  };

  /* Function to generate default configJson upon creating a config */
  generateProperties = (e, { value }) => {
    const type = value;
    const index = this.state.pipelineTypes.findIndex(obj => {
      return obj.type === type;
    });
    const schema = this.state.pipelineTypes[index]
      ? this.state.pipelineTypes[index].schema.properties
      : "";

    const getType = type => {
      if (type === "array") {
        return [];
      } else if (type === "integer") {
        return 1;
      } else return "";
    };

    const propsArray = Object.keys(schema).map(key => {
      return {
        name: key,
        type: schema[key].type,
        required: schema[key].required
      };
    });

    var properties = {};
    propsArray.forEach(prop => {
      properties[prop.name] = getType(prop.type);
    });

    this.setState({
      configEdits: {
        ...this.state.config,
        configJson: {
          ...properties,
          type: type
        }
      }
    });
  };

  /* Handle response that comes back from API call */
  handleResponse = response => {
    this.setState({ waitingResponse: true })
    if (response === 500) 
      this.setState({ responseError: "500 Error. Fix Something.", responseOK: false })
    else {
      response.json()
      .then(response => {
        this.setState({
        responseOK: response.success,
        })
        if(response.message){
          this.setState({responseError: response.message.error})
          if(response.message["missing tenants"])
            this.setState({ errorInfo: response.message["missing tenants"] })
          else this.setState({ errorInfo: "" })
        }
      })
    }
  }

  /* Handle error that comes back from API call */
  handleError = {
    inspect: () => this.setState({ inspectError: true }), 
    dismiss: () => 
      this.setState({ 
        inspectError: false, 
        responseOK: "",
        errorInfo: ""
      })
  }

  /* Call GET again on selected config to refresh page */
  refreshPage = () => {
    this.setState({ responseOK: "loading" })
    configsAPI.GET_CONFIG(config => this.setState({ config }), this.props.source, this.props.entity)
  }

  /* Dismiss status bar message */
  messageTimeout = () => {
    if (!this.state.inspectError)
      setTimeout(() => this.setState({ responseOK: "", waitingResponse: false }), 3000);
  }

  /* Post edited config to API or delete config */
  saveConfig = () => {
    const config = this.state.configEdits
    return configsAPI.UPDATE_CONFIG(config, this.refreshPage, this.handleResponse, this.messageTimeout)
  };

  toggleConfig = () => {
    return () => {
      this.setState((current, currentProps) => {
        const currentConfig = current.config;
        currentConfig.is_enabled = !currentConfig.is_enabled;
        return currentConfig;
      }, configsAPI.UPDATE_CONFIG(this.state.config, this.refreshPage, this.handleResponse, this.messageTimeout));
    };
  };

  deleteConfig = () => {
    const config = this.state.config
    return configsAPI.DELETE_CONFIG(config.source, config.entityType, this.props.refresh)
  }

  /* GET converted config upon selecting from dropdown, set edited config state */
  convertPipelineType = (e, { value }) => {
    const pipelineType = value;
    const config = this.state.config;
    return pipelinesAPI.CONVERT_PIPELINE_TYPE(config.configJson, pipelineType, data => {
      this.setState({
        configEdits: {
          source: config.source,
          entityType: config.entityType,
          configJson: data
        }
      });
    })
  }

  editConfig = (edits) => {
    this.setState({ configEdits: { ...this.state.configEdits, configJson: edits }})
  }

  render() {
    const { pipelineTypes, config, waitingResponse } = this.state;

    /* If config has not yet been created, render pipeline type selector; Otherwise render converter */
    const pipelineOptions = pipelineTypes.map(obj => { return { text: obj.type, value: obj.type }; }) 
    const PipelineSelector = () => {
      return (
        <Grid.Row>
            {config.configJson ? (
              <Form.Field inline>
                <label>
                  <Header as="h3" inverted color="blue">
                  {config.configJson.type} 
                    <Header.Subheader>
                      CONVERT PIPELINE TYPE
                      <Icon inverted color="grey" name="right arrow" />
                    </Header.Subheader>
                  </Header>
                </label>
                <Select
                  options={pipelineOptions.filter(obj => obj.type !== config.configJson.type)}
                  placeholder="Pipeline Types"
                  onChange={(e, { value }) =>
                    this.convertPipelineType(e, { value })
                  }
                />
              </Form.Field>
            ) : (
                <Form.Field inline>
                  <label>
                    <Header inverted color="blue">
                      PICK A PIPELINE TYPE
                      <Icon color="grey" name="right arrow" inverted />
                    </Header>
                  </label>
                  <Select
                    onChange={(e, { value }) =>
                      this.generateProperties(e, { value })
                    }
                    placeholder="Pipeline Types"
                    options={pipelineOptions}
                  />
                </Form.Field>
              )}
        </Grid.Row>
      );
    };

    /* Show toggle and delete if config exists */
    const StatusToggle = () => {
      if (config.configJson) {
        return (
          <Grid.Column  textAlign="center" width={3}>
            <Header 
              inverted
              content={config.is_enabled ? "ON" : "OFF"}
              as="h4" 
            />
            <Checkbox
              readOnly={waitingResponse}
              style={{backgroundColor: "darkgrey", borderRadius: "10px"}}
              toggle
              onChange={this.toggleConfig()}
              checked={config.is_enabled}
            />
          </Grid.Column>
        )
      } else return null
    }

    const DeleteButton = () => {
      if (config.configJson) {
        return (
          <Grid.Column width={3}>
            <Button
              floated="right"
              inverted
              content="DELETE CONFIGURATION"
              color="red"
              onClick={() => this.setState({ showDeleteModal: true })}
            />
          </Grid.Column> 
        )
      } else return null
    }

    /* Render schema editor column for every configJson property */
    const pipelineType = pipelineTypes[
      pipelineTypes.findIndex(i => {
        if(i.type === config.configJson.type) 
          return true
      })
    ]
    const schema = pipelineType ? pipelineType.schema : {}
    const SchemaEditors = () => {
      if (config.configJson) {
        const keys = Object.keys(config.configJson)
        const properties = [];
        keys.forEach(prop => {
          if (prop !== "type")
            properties.push(
              <Grid.Column>
                <SchemaDisplay 
                  editConfig={this.editConfig}
                  schema={schema.properties ? {...schema.properties[prop], propName: prop} : 'waiting'} 
                  title={prop}
                  currentConfigJson={config.configJson}
                />
              </Grid.Column>
            )
        })
        const numProps = properties.length;
        return [
          <Grid.Row columns={numProps}>
            {properties}
          </Grid.Row>
        ]
      } else return null
    }

    /* Return loader if config is not loaded in yet */
    if (config.source === "") {
      return (
        <Loader active />
      )
    }

    return [
      <ErrorModal 
        open={this.state.inspectError}
        close={this.handleError.dismiss}
        error={this.state.responseError}
        errorInfo={this.state.errorInfo}
      />,
      <DeleteModal
        object={config}
        open={this.state.showDeleteModal}
        deny={() => this.setState({showDeleteModal: false})}
        confirm={this.deleteConfig()}
      />,
      <Grid
        stackable 
        divided 
        inverted
      >
        {/**** ROW 1: Edit Configuration/Create Configuration header, status bar, save/create button ****/}
        <Grid.Row columns={3}>
          <Grid.Column width={10}>
            <Header inverted as="h1">
              {config.configJson ? "EDIT CONFIGURATION" : "CREATE NEW CONFIGURATION"}
            </Header>
          </Grid.Column>
          <Grid.Column width={3}>
            <StatusBar 
              success={this.state.responseOK} 
              messageType={this.state.messageType}
              inspectError={this.handleError.inspect}
            /> 
          </Grid.Column>
          <Grid.Column width={3}>
            <Button
              floated="right"
              content={config.configJson ? "SAVE CONFIGURATION" : "CREATE CONFIGURATION"}
              disabled={!this.state.configEdits.configJson}
              inverted={config.configJson ? true : false}
              color="green"
              style={config.configJson ? {} : {backgroundColor: "green", color: "white"}}
              onClick={this.saveConfig()}
            />
          </Grid.Column>
        </Grid.Row>
        <Divider inverted/>

        {/**** ROW 2: Source/Entity Type of selected config, status toggle, delete button ****/}
        <Grid.Row columns={3}>
          <Grid.Column width={10}>
            <Header inverted as="h2">
              <Header.Subheader>SOURCE / ENTITY TYPE</Header.Subheader>
              {config.source} / {config.entityType}
            </Header>
          </Grid.Column>
          <StatusToggle />
          <DeleteButton />
        </Grid.Row>
        <Divider inverted/>

        {/**** ROW 3: Pipeline type selector/converter ****/}
        <Grid.Row>
          <Grid.Column>
            <Form>
              {PipelineSelector()}
            </Form>
          </Grid.Column>
        </Grid.Row>
        <Divider inverted /> 

        {/**** ROW 4: Schema Editors ****/}
        {SchemaEditors()}
      </Grid>
    ];
  }

  componentWillUnmount() {
    this.setState({ state: false })
  }
}

export default ConfigEditPage;