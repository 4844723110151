import React, { Component } from 'react'
import { Loader, Header, Icon, Grid } from 'semantic-ui-react'

class StatusBar extends Component {
  render(){
    const { success } = this.props
    const color = success ? "green" : "red"
    const successMessage = "CONFIG SAVED"
    const errorMessage = "ERROR SAVING CONFIG"
    if (success==="")
      return (
        <div style={{textAlign: "center"}}>
        <Header inverted as="h4">
          UDPATE RESPONSE
          <Header.Subheader content="Idle" />
        </Header>
        </div>
      )
    if (success==="loading")
      return  (
        <Loader inverted content="UPDATING CONFIG" active />
      )
    return (
      <Grid centered>
        <Grid.Row style={{alignContent: "left"}}>
        {success ? 
          <Header color={color}>
            <Icon name='check' />
            {successMessage}
          </Header>
        :
          <Header color={color}>
            {errorMessage}
            <Header.Subheader
              style={{color: "pink"}}
              content="CLICK TO VIEW INFO" 
              onClick={this.props.inspectError}
            />
          </Header>
        }
        </Grid.Row>
      </Grid>
    )
  }
}

export default StatusBar