import React from "react";
import { Link } from "react-router-dom"
import { Modal, Button } from "semantic-ui-react";

const DeleteModal = (props = {}) => {
  const { open, confirm, deny, object } = props;
  return (
    <Modal dimmer="inverted" open={open} size="small">
      <Modal.Header content="Are you sure you want to delete the following configuration?" />
      <Modal.Content>
        Source: <strong>{object.source}</strong>
        <br />
        Entity Type: <strong>{object.entityType}</strong>
      </Modal.Content>
      <Modal.Actions>
        <Button
          as={Link}
          to="/"
          style={{ backgroundColor: "red", color: "white" }}
          onClick={confirm}
          content="YES"
        />
        <Button
          onClick={deny}
          content="Cancel"
        />
      </Modal.Actions>
    </Modal>
  );
};

export default DeleteModal;
