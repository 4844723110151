import React, { Component } from "react";
import { Header, Button, Divider, Grid, Loader } from "semantic-ui-react";
import ConfigsTable from "../Components/Table/configsTable";
import DeleteModal from "../Components/Modals/deleteModal";
import CopyModal from "../Components/Modals/copyModal";
import ErrorModal from "../Components/Modals/errorModal"
import CreateModal from "../Components/Modals/createModal";
import { configsAPI } from "../SDK/client";
import StatusBar from "../Components/updateStatusBar"

class ConfigLibraryPage extends Component {
  state = {
    showDeleteModal: false,
    showCopyModal: false,
    showCreateModal: false,
    inspectMessage: false,
    waitingResponse: false,
    responseOK: "",
    responseError: "",
    errorInfo: "",
    configToDelete: {
      source: "",
      entityType: ""
    },
    configJsonCopy: {}
  };

  refreshPage = () => {
    this.props.refresh()
    this.hideCopyModal()
    this.hideCreateModal()
    this.hideDeleteModal()
  }

  /** Handle response for status bar display **/
  handleResponse = response => {
    if (response === 500) 
      this.setState({ responseError: "500 Error. Fix Something.", responseOK: false })
    else {
      response.json()
      .then(response => {
        this.setState({
        responseOK: response.success,
        })
        if(response.message){
          this.setState({responseError: response.message.error})
          if(response.message["missing tenants"])
            this.setState({errorInfo: response.message["missing tenants"]})
        }
      })
    }
  }

  messageTimeout = () => {
    if (!this.state.inspectMessage)
      setTimeout(() => this.setState({ waitingResponse: false, responseOK: "" }), 3000);
  }

  updateLoader = () => this.setState({ waitingResponse: true, responseOK: "loading" });

  handleError = {
    inspect: () => this.setState({ inspectMessage: true }), 
    dismiss: () => 
      this.setState({ 
        inspectMessage: false, 
        waitingResponse: false, 
        responseOK: "" 
      })
  }


  /** Toggling and deleting configs from table menu **/
  toggleConfig = index => {
    this.updateLoader();
    const config1 = {
      source: this.props.configsList[index].source,
      entityType: this.props.configsList[index].entityType,
      is_enabled: !this.props.configsList[index].is_enabled
    };
    return configsAPI.UPDATE_CONFIG(
      config1,
      this.refreshPage,
      this.handleResponse,
      this.messageTimeout
    );
  };

  deleteConfig = () => {
    const source = this.state.configToDelete.source;
    const entityType = this.state.configToDelete.entityType;
    return configsAPI.DELETE_CONFIG(
      source,
      entityType,
      this.refreshPage,
    );
  };

  /** Modal control functions **/
  showCreateModal = () => {
    this.setState({ showCreateModal: true });
  };

  showDeleteModal = index => {
    return () => {
      this.setState({
        showDeleteModal: true,
        configToDelete: {
          source: this.props.configsList[index].source,
          entityType: this.props.configsList[index].entityType
        }
      });
    };
  };

  showCopyModal = index => {
    return () => {
      this.setState({
        showCopyModal: true,
        configJsonCopy: this.props.configsList[index].configJson,
      });
    };
  };

  hideCreateModal = () => {
    this.setState({ showCreateModal: false });
  };

  hideDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
      configToDelete: {
        source: "",
        entityType: ""
      }
    });
  };

  hideCopyModal = () => {
    this.setState({
      showCopyModal: false,
      configJsonCopy: {}
    });
  };


  render() {
    const { configsList } = this.props;

    /* Return loader if configsList is still loading */
    if (configsList.length === 0) 
      return <Loader active /> 

    return [
      <DeleteModal
        key="delete"
        object={this.state.configToDelete}
        open={this.state.showDeleteModal}
        confirm={this.deleteConfig()}
        deny={this.hideDeleteModal}
      />,
      <CopyModal
        key="copy"
        configsList={configsList}
        closeModal={this.hideCopyModal}
        open={this.state.showCopyModal}
        refreshPage={this.refreshPage}
        show={this.handleResponse}
        close={this.messageTimeout}
        loader={this.updateLoader}
        configJsonCopy={this.state.configJsonCopy}
      />,
      <CreateModal
        key="create"
        open={this.state.showCreateModal}
        closeModal={this.hideCreateModal}
        configsList={configsList}
        handleCreate={this.props.handleCreateConfig}
      />,
      <ErrorModal
        key="error"
        open={this.state.inspectMessage}
        close={this.handleError.dismiss}
        error={this.state.responseError}
        errorInfo={this.state.errorInfo}
      />,
      <Grid key="grid" inverted divided>
        <Grid.Row columns={3}>
          <Grid.Column width={8}>
            <Header inverted as="h1" content="CONFIGURATION LIBRARY" />
          </Grid.Column>
          <Grid.Column width={4}>
            <StatusBar 
              success={this.state.responseOK} 
              inspectError={this.handleError.inspect}
            /> 
          </Grid.Column>
          <Grid.Column width={4}>
            <Button
              style={{ display: "inline-block" }}
              content="CREATE NEW CONFIGURATION"
              onClick={this.showCreateModal}
              inverted
            />
          </Grid.Column>
        </Grid.Row>
        <Divider inverted />
        <Grid.Row>
          <ConfigsTable
            {...this.state}
            toggleStatus={this.toggleConfig}
            configsList={configsList}
            showCopyModal={this.showCopyModal}
            showDeleteModal={this.showDeleteModal}
          />
        </Grid.Row>
      </Grid>
    ];
  }
}

export default ConfigLibraryPage;
