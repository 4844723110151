import React from "react";
import { Modal, Button, Divider } from "semantic-ui-react";

const ErrorModal = (props = {}) => {
  const { open, close, error, errorInfo } = props;
  var info = ``
  if (Array.isArray(errorInfo)){
    errorInfo.forEach(item => info += `${item}; `)
    console.log(info)
  }
  return (
    <Modal dimmer="inverted" open={open}>
      <Modal.Header content="Error Information" />
      <Modal.Content>
        {error}
        {errorInfo ? 
          <div>
            <Divider/>
            <i>{info}</i> 
          </div> 
        : <div />}
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={close}
          content="OK"
          color="red"
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ErrorModal;
