import React, { Component } from "react";
import { Modal, Button, Form, Divider } from "semantic-ui-react";
import _ from "lodash";
import { configsAPI, configsUtil } from '../../SDK/client'

class CopyModal extends Component {
  state = {
    config: {
      source: "",
      entityType: ""
    },
    submitDisabled: true
  };

  // Check if config already exists before copying or creating (or if pipelineType is empty) //
  handleChange = _.debounce(async (e, { name, value }) => {
    await this.setState({
      config: { ...this.state.config, [name]: value.toUpperCase().trim() }
    });
    await this.setState({
      submitDisabled: configsUtil.SUBMIT_DISABLED(this.state.config, this.props.configsList)
    });
  }, 200);

  refreshPage = () => {
    this.props.loader()
    this.props.refreshPage()
  }

  copyConfig = () => {
    const config = {
      ...this.state.config,
      configJson: this.props.configJsonCopy
    };
    return configsAPI.UPDATE_CONFIG(
      config,
      this.refreshPage,
      this.props.show,
      this.props.close
    )
  };

  render() {
    const { open, closeModal } = this.props;
    return (
      <Modal size="small" dimmer="inverted" open={open}>
        <Modal.Header content="Copy Configuration" />
        <Modal.Content>
          Please enter a new source and entity for the configuration you would
          like to copy. You will not be able to submit if the source/entity
          combination already exists.
          <br />
          <br />
          <i>Only alphanumeric characters, no spaces.</i>
          <Divider />
          <Form>
            <Form.Input
              name="source"
              onChange={this.handleChange}
              label="Source"
            />
            <Form.Input
              name="entityType"
              onChange={this.handleChange}
              label="Entity"
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            style={{ backgroundColor: "green", color: "white" }}
            content="Submit"
            disabled={this.state.submitDisabled}
            onClick={this.copyConfig()}
          />
          <Button onClick={closeModal} content="Cancel" />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default CopyModal;
