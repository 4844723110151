import React, { Component } from "react";
import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
import { Container, Menu, Image } from 'semantic-ui-react'
import ConfigLibraryPage from "./Pages/configLibrary.js";
import ConfigEditPage from "./Pages/configEdit.js";
import logo from './Images/dais-logo-small@2x.png'
import { configsAPI } from './SDK/client'

class App extends Component {
  state={
    configsList: []
  }

  refreshLibrary = () => {
    configsAPI.LOAD_CONFIGS(data => this.setState({configsList: data}))
  }

  async componentDidMount() {
    configsAPI.LOAD_CONFIGS(data => this.setState({configsList: data}))
  }

  render() {
    document.body.style.backgroundColor = "#000000";
    return (
      <BrowserRouter>
      <Container style={{marginTop: "50px"}}>
        <Menu fixed="top">
          <Menu.Item as={Link} to="/">
            <Image src={logo} size='tiny'/>
            PIPELINE CONFIGURATIONS
          </Menu.Item>
        </Menu>
        <Switch>
          <Route
            exact
            path="/"
            render={() => 
              <ConfigLibraryPage 
                refresh={this.refreshLibrary} 
                configsList={this.state.configsList} 
            />}
          />
          <Route
            exact
            path="/configs/:source/:entityType"
            render={({ match }) => (
              <ConfigEditPage
                refresh={this.refreshLibrary}
                source={match.params.source}
                entity={match.params.entityType}
              />
            )}
          />
        </Switch>
      </Container>
      </BrowserRouter>
    );
  }
}

export default App;
