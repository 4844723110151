/*** API calls hitting ingestion-config-service ***/
export const configsAPI = {
  LOAD_CONFIGS: function (callback) {
    return fetch("http://ingestion-config-service.service.dev.dais.com/configs")
      .then(response => response.json())
      .then(data => callback(data));
  },

  GET_CONFIG: function(callback, source, entity) {
    return fetch(`http://ingestion-config-service.service.dev.dais.com/fullConfig/${source}/${entity}`)
      .then(response => {
        if (response.status === 404) callback({source: source, entityType: entity})
        else {
          response.json().then(response => callback(response))
        }
      });
  },

  UPDATE_CONFIG: function (body, refresh, callback, messageTimeout) {
    return async () => {
      await fetch(`http://ingestion-config-service.service.dev.dais.com/config`, {
        method: "PUT",
        body: JSON.stringify(body),
        headers: { "content-type": "application/json" }
      }).then(response => {
        console.log(response)
        if(response.status===500) callback(500)
        else callback(response)
      });
      await refresh();
      await messageTimeout();
    };
  },

  DELETE_CONFIG: function (source, entity, refresh) {
    return async () => {
      await fetch(`http://ingestion-config-service.service.dev.dais.com/configs/${source}/${entity}`,
        {
          method: "DELETE"
        }
      ).then(response => console.log(response));
      await refresh()
    };
  }
}

/*** API calls hitting pipeline-validator ***/
export const pipelinesAPI = {
  LOAD_PIPELINE_TYPES: function (callback) {
    return fetch("http://pipeline-validator.service.dev.dais.com/schema/com.dais.dataingestion.pipeline.PipelineConfig")
      .then(response => response.json())
      .then(data => {
        return data.availableTypes.map(type => {
          return { type: type.typeName, id: type.id };
        });
      })
      .then(data => callback(data))
  },

  CONVERT_PIPELINE_TYPE: function (body, type, callback) {
    return fetch(
      `http://pipeline-validator.service.dev.dais.com/convert/${type}`,
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "content-type": "application/json"
        }
      }
    ).then(response => response.json())
      .then(data => {
        console.log(data)
        callback(data)
      })
  },

  GET_SCHEMA: function (url, callback) {
    return fetch(`http://pipeline-validator.service.dev.dais.com/schema/${url}`)
      .then(data => data.json())
      .then(data => callback(data))
  }
}

/*** Utilities for working with configs ***/
export const configsUtil = {
  SUBMIT_DISABLED: function (value, list) {
    for (let i = 0; i < list.length; i++) {
      if (
        list[i].source === value.source &&
        list[i].entityType === value.entityType
      ) {
        return true;
      }
    }
    if (value.source === "" || value.entityType === "") {
      return true;
    }
    return false;
  }
}
