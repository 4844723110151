import React, { Component } from "react";
import { Modal, Button, Divider, Form } from "semantic-ui-react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { configsUtil } from '../../SDK/client'

class CreateModal extends Component {
  state = {
    config: {
      source: "",
      entityType: ""
    },
    submitDisabled: true
  };

  // Check if config already exists before copying or creating (or if pipelineType is empty) //
  handleChange = _.debounce(async (e, { name, value }) => {
    await this.setState({
      config: { ...this.state.config, [name]: value.toUpperCase().trim() }
    });
    await this.setState({
      submitDisabled: configsUtil.SUBMIT_DISABLED(this.state.config, this.props.configsList)
    });
  }, 200);

  render() {
    const { open, closeModal } = this.props;
    return (
      <Modal size="small" dimmer="inverted" open={open}>
        <Modal.Header content="Create New Configuration" />
        <Modal.Content>
          Please enter a new source and entity for the
          configuration you would like to create. You will not be able to submit
          if the source/entity combination already exists.
          <br />
          <br />
          <i>Only alphanumeric characters, no spaces.</i>
          <Divider />
          <Form>
            <Form.Input
              label="Source"
              name="source"
              onChange={this.handleChange}
            />
            <Form.Input
              label="Entity"
              name="entityType"
              onChange={this.handleChange}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            style={{ backgroundColor: "green", color: "white" }}
            content="Continue"
            disabled={this.state.submitDisabled}
            as={Link}
            onClick={this.createConfigPage}
            to={`configs/${this.state.config.source}/${
              this.state.config.entityType
              }`}
          />
          <Button onClick={closeModal} content="Cancel" />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default CreateModal;
