import React, { Component } from 'react'
import { Accordion, Loader, Header, Form } from 'semantic-ui-react'

class SchemaDisplay extends Component {
  state = {
    panels: [],
    configJson: {}
  }

  componentWillReceiveProps(nextProps){
    if (nextProps.schema !== this.props.schema) {
      this.setState((state, props) => ({
        panels: [{
          key: "starter_panel",
          title: {
            content: <Header as="h4" color="blue" style={{margin: "0px", display: "inline-block"}} content={nextProps.title} />
          },
          content: {
            content: this.renderContent(nextProps.schema)
          }
        }],
        configJson: nextProps.currentConfigJson
      }))
    }
  }

  renderContent(schema) {
    if (schema.type === "integer") {
      return (
        <Form>
          <Form.Input
            name={schema.propName}
            onChange={(e, {name, value}) => this.setState({ configJson: {...this.state.configJson, [name]: value}})}
            fluid 
            label={schema.description}
            placeholder={`MIN: ${schema.minimum}, MAX: ${schema.maximum}`}
            type="number"
            max={schema.maximum}
            min={schema.minimum}
          />
        </Form>
      )
    }
    else if (schema.type === "string") {
      return (
        <Form>
          {schema.enum ? 
            <Form.Select 
              fluid 
              options={schema.enum} 
              label={schema.description}
            />
            : 
            <Form.Input 
              fluid 
              label={schema.description}
            />
          }
        </Form>
      )
    }
    else return null
  }


  render() {
    console.log(this.state.configJson)
    const { panels } = this.state
    const { schema } = this.props 
    if (schema === 'waiting') {
      return <Loader active />
    }
    else return (
      <Accordion styled defaultActiveIndex={0} panels={panels} />
    )
  }
}

export default SchemaDisplay