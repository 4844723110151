import React, { Component } from "react";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import { Checkbox, Dropdown, Icon } from "semantic-ui-react";
import "./react-table.css";

class ConfigsTable extends Component {
  toggleStatus = index => {
    return () => {
      this.setState((current, currentProps) => {
        const configs = currentProps.configsList;
        const currentConfig = configs[index];
        currentConfig.is_enabled = !currentConfig.is_enabled;
        return currentConfig;
      }, this.props.toggleStatus(index));
    };
  };

  render() {
    const { showCopyModal, showDeleteModal } = this.props;

    const columns = [
      {
        Header: "Source",
        accessor: "source",
        filterMethod: (filter, row) => {
          return row[filter.id].startsWith(filter.value);
        },
        Filter: ({ filter, onChange }) =>
          sourceEntFilter("source", "1", { filter, onChange }),
      },
      {
        Header: "Entity ",
        accessor: "entityType",
        Filter: ({ filter, onChange }) =>
          sourceEntFilter("entityType", "2", { filter, onChange }),
      },
      {
        Header: "Status",
        accessor: "is_enabled",
        sortable: false,
        Cell: row => (
          <div style={{ textAlign: "center" }}>
            <Checkbox
              toggle
              readOnly={this.props.waitingResponse}
              checked={!!row.original.is_enabled}
              label={statusText(row.original.is_enabled)}
              onChange={this.toggleStatus(row.index)}
            />
          </div>
        ),
        filterMethod: (filter, row) => {
          if (filter.value === "true") {
            return row[filter.id] === true;
          } else if (filter.value === "false") {
            return row[filter.id] === false;
          } else {
            return true;
          }
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={e => onChange(e.target.value)}
            style={{
              width: "100%",
              verticalAlign: "-webkit-baseline-middle",
              height: "25px"
            }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Show All</option>
            <option value="true">Enabled</option>
            <option value="false">Disabled</option>
          </select>
        ),
        maxWidth: 200
      },
      {
        Header: "Actions",
        id: "row",
        sortable: false,
        Cell: row => {
          return (
            <div>
              <Dropdown
                style={{ position: "absolute" }}
                trigger={
                  <Icon size="large" color="grey" name="horizontal ellipsis" />
                }
                icon={null}
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    content="Copy"
                    onClick={showCopyModal(row.index)}
                  />
                  <Dropdown.Item
                    content="Edit"
                    as={Link}
                    to={`/configs/${row.original.source}/${
                      row.original.entityType
                    }`}
                  />
                  <Dropdown.Item
                    style={{ color: "red" }}
                    content="Delete"
                    onClick={showDeleteModal(row.index)}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
          );
        },
        Filter: () => {
          return <div />;
        },
        maxWidth: 100
      }
    ];

    const sourceEntFilter = (input, ops, { filter, onChange }) => {
      const options = this.props.configsList
        .map(config => config[input])
        .filter((config, c, s) => s.lastIndexOf(config) === c)
        .map(function(value) {
          return (
            <option key={value} value={value}>
              {value}
            </option>
          );
        });

      options.sort((a, b) => {
        var A = a.props.value.toLowerCase();
        var B = b.props.value.toLowerCase();
        if (A < B) {
          return -1;
        }
        if (B > A) {
          return 1;
        }
        return 0;
      });

      return [
        <input
          id={input}
          onChange={e => onChange(e.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
          list={ops}
        />,
        <datalist id={ops}>{options}</datalist>
      ];
    };

    const statusText = value => {
      return value ? "ON" : "OFF";
    };

    return (
        <ReactTable
          style={{width: "100%"}}
          filterable
          data={this.props.configsList}
          columns={columns}
          className="-striped"
        />
    );
  }
}

export default ConfigsTable;
